import type { AxiosResponse } from 'axios';
import { detect, BrowserInfo } from 'detect-browser';
import { Store } from 'vuex';
import type { Context } from '@nuxt/types';
import Cookies from 'js-cookie';
import {
  HIDE_SAFARI_PUSH_DIALOG,
  SHOW_SAFARI_PUSH_DIALOG,
  SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS,
  UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS,
} from '~/constants';
import { IPushApi } from '~/services/push';
import isNotificationsSupported from '~/utils/isNotificationsSupported';
import { PUSH_VAPID_PUBLIC_KEY, SAFARI_COOKIE_NAME, SAFARI_PUSH_ID, SAFARI_PUSH_WEB_SERVICE_URL } from '~/constants/config';

export interface ISubscription {
  token: string;
  type: 'safari' | 'web';
  auth: string | null;
  p256dh: string | null;
}

function urlBase64ToUint8Array (base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

class PushNotification {
  private readonly SAFARI_PUSH_ID: string;
  private readonly SAFARI_PUSH_WEB_SERVICE_URL: string;
  private readonly PUSH_VAPID_PUBLIC_KEY: string;
  private readonly subscribeCallBack: () => void;
  private readonly unsubscribeCallBack: () => void;
  private readonly showSafariPushDialog: () => void;
  private readonly hideSafariPushDialog: () => void;

  private readonly isSafari: boolean;
  private readonly browser: BrowserInfo;
  private permissionData: any = null;
  private readonly $pushApi: IPushApi;

  constructor (
    SAFARI_PUSH_ID: string,
    SAFARI_PUSH_WEB_SERVICE_URL: string,
    PUSH_VAPID_PUBLIC_KEY: string,
    subscribeCallBack: () => void,
    unsubscribeCallBack: () => void,
    showSafariPushDialog: () => void,
    hideSafariPushDialog: () => void,
    $pushApi: IPushApi,
  ) {
    this.isSafari = 'safari' in window;
    this.browser = detect() as BrowserInfo || {
      name: navigator.userAgent,
      version: '',
      os: '',
    };

    this.SAFARI_PUSH_ID = SAFARI_PUSH_ID;
    this.SAFARI_PUSH_WEB_SERVICE_URL = SAFARI_PUSH_WEB_SERVICE_URL;
    this.PUSH_VAPID_PUBLIC_KEY = PUSH_VAPID_PUBLIC_KEY;
    this.subscribeCallBack = subscribeCallBack;
    this.unsubscribeCallBack = unsubscribeCallBack;
    this.showSafariPushDialog = showSafariPushDialog;
    this.hideSafariPushDialog = hideSafariPushDialog;
    this.$pushApi = $pushApi;
  }

  public requestSafariPermission = () => {
    const {
      SAFARI_PUSH_WEB_SERVICE_URL,
      SAFARI_PUSH_ID,
      sendNewSubToServer,
      subscribeCallBack,
    } = this;

    // Убедитесь, что пользователь может получать push-уведомления Safari.
    if ('safari' in window && 'pushNotification' in window.safari) {
      const permissionData = window.safari.pushNotification.permission(SAFARI_PUSH_ID);

      // eslint-disable-next-line no-console
      // console.warn('permissionData', permissionData, SAFARI_PUSH_ID, SAFARI_PUSH_WEB_SERVICE_URL);

      checkRemotePermission(permissionData);
    }

    function checkRemotePermission (permissionData: any) {
      // eslint-disable-next-line no-console
      // console.warn('checkRemotePermission permissionData', permissionData);

      if (permissionData.permission === 'default') {
        // Это новый URL-адрес веб-службы, и его достоверность неизвестна.

        // eslint-disable-next-line no-console
        // console.warn('SAFARI_PUSH_WEB_SERVICE_URL, SAFARI_PUSH_ID', SAFARI_PUSH_WEB_SERVICE_URL, SAFARI_PUSH_ID);

        window.safari.pushNotification.requestPermission(
          // URL-адрес веб-службы.
          SAFARI_PUSH_WEB_SERVICE_URL,

          // Идентификатор push-уведомлений веб-сайта.
          SAFARI_PUSH_ID,

          // Данные которые вы решили отправить на свой сервер чтобы помочь вам идентифицировать пользователя.
          {},

          // Функция обратного вызова.
          checkRemotePermission,
        );
      } else if (permissionData.permission === 'denied') {
        // Пользователь сказал нет.
        // alert('Не удалось подписаться на push-уведомления. Включите разрешение в браузере');
      } else if (permissionData.permission === 'granted') {
        // URL-адрес веб-службы является действительным поставщиком push-уведомлений, и пользователь сказал «да».
        // permissionData.deviceToken теперь доступно для использования.
        sendNewSubToServer({
          token: permissionData.deviceToken,
          type: 'safari',
          p256dh: null,
          auth: null,
        })
          .then(subscribeCallBack);
      }
    };

    // window.safari.pushNotification.requestPermission(
    //   this.SAFARI_PUSH_WEB_SERVICE_URL, // The web service URL.
    //   this.SAFARI_PUSH_ID, // The Website Push ID.
    //   {}, // Data that you choose to send to your server to help you identify the user.
    //   () => {
    //     this.hideSafariPushDialog();
    //     this.permissionData = window.safari.pushNotification.permission(this.SAFARI_PUSH_ID);
    //     if (this.permissionData && this.permissionData.permission === 'granted') {
    //       // The web service URL is a valid push provider, and the user said yes.
    //       // permissionData.deviceToken is now available to use.
    //       this.sendNewSubToServer({
    //         token: this.permissionData.deviceToken,
    //         type: 'safari',
    //         p256dh: null,
    //         auth: null,
    //       }).then(this.subscribeCallBack);
    //     }
    //   }, // The callback function.
    // );
  }

  private subscribeWeb = (): void => {
    setTimeout(() => {
      this.getPushSubObject().then((pushSubObject) => {
        if (!pushSubObject) {
          return;
        }
        this.sendNewSubToServer({
          token: pushSubObject.endpoint,
          type: 'web',
          p256dh: pushSubObject.keys.p256dh,
          auth: pushSubObject.keys.auth,
        }).then(this.subscribeCallBack);
      }).catch(() => {});
    }, 500);
  }

  private sendNewSubToServer = (subscription: ISubscription): Promise<any> => {
    return this.$pushApi.addSubscription(subscription, this.browser);
  }

  private tryToSubscribe = (force: boolean = false): void => {
    if (this.isSafari) {
      if (force && SAFARI_COOKIE_NAME) {
        Cookies.remove(SAFARI_COOKIE_NAME);
      }
      this.showSafariPushDialog();
    } else {
      this.subscribeWeb();
    }
  }

  public updateSubscriptionStatus = (): void => {
    if (!isNotificationsSupported()) {
      return;
    }

    switch (Notification?.permission) {
      // проверку делаю в обоих случая, потому что в сафари есть баг:
      // после очистки истории, он неправильно обновляет состояние Notification.permission
      // и даже если ты разрешил, то выдает 'default'
      case 'default':
      case 'granted':
        this.getToken()
          .then((token: string) => {
            return this.$pushApi.getDeviceInfo(token);
          })
          .then((resp: AxiosResponse) => {
            // дейвайс есть в БД
            if (resp.status === 200) {
              if (resp.data.data.subscribed) {
                this.subscribeCallBack();
              } else {
                this.unsubscribeCallBack();
              }
              // девайса нет в БД, попробуем добавить его в подписчики
            } else {
              this.tryToSubscribe();
            }
          })
          .catch(this.tryToSubscribe);
    }
  }

  public unsubscribe = (): void => {
    if (!isNotificationsSupported()) {
      return;
    }

    switch (Notification?.permission) {
      case 'default':
      case 'granted':
        this.getToken().then((token: string) => {
          return this.unsubRequest(token);
        }).catch(() => {});
        break;
      default:
        alert('Не удалось подписаться на push-уведомления. Включите разрешение в браузере');
    }
  }

  public subscribe = (): void => {
    if (!isNotificationsSupported()) {
      return;
    }

    // eslint-disable-next-line no-console
    // console.warn('Notification?.permission', Notification?.permission);

    switch (Notification?.permission) {
      case 'default':
      case 'granted':
        this.getToken().then((token: string) => {
          return this.subRequest(token);
        }).catch(() => {
          this.tryToSubscribe(true);
        });
        break;
      default:
        alert('Не удалось подписаться на push-уведомления. Включите разрешение в браузере');
    }
  }

  private subRequest = (token: string): void => {
    this.$pushApi.subscribe(token).then((resp: AxiosResponse) => {
      if (resp.data.success) {
        this.subscribeCallBack();
      }
    }).catch(() => {});
  }

  private unsubRequest = (token: string): void => {
    this.$pushApi.unsubscribe(token).then((resp) => {
      if (resp.data.success) {
        this.unsubscribeCallBack();
      }
    }).catch(() => {});
  }

  private getPushSubObject = (): Promise<any> => {
    if (!navigator?.serviceWorker) {
      return Promise.resolve(null);
    }

    navigator.serviceWorker.register('/sw.js');
    return navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(this.PUSH_VAPID_PUBLIC_KEY),
      };
      return registration.pushManager.subscribe(subscribeOptions);
    }).then((pushSubscription: PushSubscription) => {
      const pushSubString = JSON.stringify(pushSubscription);
      return JSON.parse(pushSubString);
    });
  }

  public getToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (this.isSafari) {
        const deviceToken = window.safari.pushNotification.permission(this.SAFARI_PUSH_ID).deviceToken;
        if (deviceToken) {
          resolve(deviceToken);
        } else {
          reject(deviceToken);
        }
      } else {
        this.getPushSubObject().then((pushSubObject) => {
          pushSubObject && resolve(pushSubObject.endpoint);
        }).catch();
      }
    });
  }

  public sendTestPush = (): void => {
    this.getToken().then(token => this.$pushApi.sendTestPush(token));
  }
}

// Used by NavMenu
export function getPushNotificationsManager (store: Store<IRootState>, $pushApi: IPushApi, subscribeCallBack = () => {}, unsubscribeCallBack = () => {}): PushNotification {
  return new PushNotification(
    SAFARI_PUSH_ID,
    SAFARI_PUSH_WEB_SERVICE_URL,
    PUSH_VAPID_PUBLIC_KEY,
    () => {
      store.commit(SUBSCRIBE_TO_WEB_PUSH_NOTIFICATIONS);
      subscribeCallBack();
    },
    () => {
      store.commit(UNSUBSCRIBE_FROM_WEB_PUSH_NOTIFICATIONS);
      unsubscribeCallBack();
    },
    () => {
      if (!Cookies.get(SAFARI_COOKIE_NAME)) {
        store.commit(SHOW_SAFARI_PUSH_DIALOG);
      }
    },
    () => store.commit(HIDE_SAFARI_PUSH_DIALOG),
    $pushApi,
  );
}

const task = (store: Context['store'], app: Context['app']): void => {
  const pushNotificationsManager = getPushNotificationsManager(store, app.$pushApi);
  // это для отладки
  window.sendTestPush = pushNotificationsManager.sendTestPush;
  window.getPushToken = pushNotificationsManager.getToken;

  window.addEventListener('load', () => {
    setTimeout(pushNotificationsManager.updateSubscriptionStatus, 1000);
  }, false);

  // если вручную сменилось разрешение на пуши на granted, пробуем добавить нового подписчика
  if ('permissions' in navigator) {
    const permissionsChangeCallback = (notificationPerm: PermissionStatus) => {
      notificationPerm.onchange = () => {
        if (notificationPerm.state === 'granted') {
          pushNotificationsManager.updateSubscriptionStatus();
        }
      };
    };
    navigator.permissions
      .query({ name: 'notifications' })
      .then(permissionsChangeCallback)
      .catch();
  }
};

export default ({ store, app }: Context) => {
  window.onNuxtReady(() => task(store, app));
};
